<template>
	<div class="bg1">
		<div class="page-content" style="margin-top: 40px;">
			<el-breadcrumb separator=">" style="position: absolute;margin-top: -44px;">
				<el-breadcrumb-item :to="{ path: '/index' }">Home</el-breadcrumb-item>
				<el-breadcrumb-item>HCP Query</el-breadcrumb-item>
			</el-breadcrumb>
			<p style="float: right;">A total of <span style="color: #b40b18;font-size: 20px;">
					{{citationsNum}}
				</span> authors
				have inquired the
				number of citations</p>
		</div>
		<div class="page-content">
			<p style="position: absolute;color: white;font-size: 30px;margin-left: 262px;margin-top: 111px;">
				<span>How many times cited need to be a</span>
				<span style="color: #fe7568;font-size: 34px;">&nbsp;Highly Cited Paper</span>
				<span>
					<el-image style="height: 40px;" :src="require('@/assets/images/HCP.png')"></el-image>
				</span>?
			</p>
			<div style="width: 1200px;height: 1600px;background-color: #f3f9fc;margin-top: 198px;position: absolute;">
				<div style="text-align: center;">
					<p>
						<el-icon style="font-size: 20px;color: #6b57a5;">
							<LocationFilled />
						</el-icon>
						<span style="font-size: 20px;margin-right: 5px;">
							Live Testing citation range for Highly Cited Paper in Top Paper
						</span>
						<el-icon style="font-size: 20px;color: #6b57a5;">
							<LocationFilled />
						</el-icon>
					</p>
				</div>
				<div>
					<el-card shadow="hover"
						style="width: 1100px;height: 520px;background-color: white;margin: 30px auto;">
						<p style="font-size: 20px;">
							<el-icon style="margin-right: 5px;font-size: 18px;">
								<List />
							</el-icon>Measuring Citing Nubmers
						</p>
						<el-divider style="height: 2px;width: 318px;margin-top: -12px;" />
						<el-divider style="height: 2px;width: 125px;margin-top: -26px;background-color: #6b57a5;" />
						<el-form :inline="true" :model="form" :rules="rules" ref="form" label-position='right'
							class="demo-form-inline">
							<el-form-item label="Title" style="width: 48%;margin-right: 20px;" label-width="120px"
								required prop="fieldA">
								<el-input v-model="form.fieldA" placeholder="" style="width: 330px;" />
							</el-form-item>
							<el-form-item label="Publishing Year" style="width: 48%;margin-right: 20px;"
								label-width="188px" required prop="fieldB">
								<el-input v-model="form.fieldB" placeholder="2012-2022" style="width: 330px;" />
							</el-form-item>
							<el-form-item label="Journal" style="width: 48%;margin-right: 20px;" label-width="120px"
								required prop="fieldC">
								<el-input v-model="form.fieldC" placeholder="" style="width: 330px;" />
							</el-form-item>
							<el-form-item label="Google Scholar Citations" style="width: 48%;margin-right: 20px;"
								required label-width="188px" prop="fieldD">
								<el-input v-model="form.fieldD" placeholder="" style="width: 330px;" />
							</el-form-item>
							<el-form-item label="Doi" style="width: 48%;margin-right: 20px;" label-width="120px"
								prop="fieldE">
								<el-input v-model="form.fieldE" placeholder="" style="width: 330px;" />
							</el-form-item>
							<el-form-item label="E-mail" style="width: 48%;margin-right: 20px;" required
								label-width="188px" prop="fieldF">
								<el-input v-model="form.fieldF" placeholder="" style="width: 330px;" />
							</el-form-item>
							<el-form-item style="float: right;">
								<el-button type="danger" @click="reset('form')">Reset</el-button>
							</el-form-item>
							<el-form-item>
								<el-button type="danger" @click="startCalculating('form')"
									style="margin: 40px 440px;background-color: #6b57a5;border-color:#6b57a5;width: 200px;">
									Start
									Calculating</el-button>
							</el-form-item>
						</el-form>
						<!-- <p style="float: right;margin-top: -18px;">
							<el-icon style="margin-right: 5px;">
								<Comment />
							</el-icon><span style="margin-right: 5px;">Comments</span>
							<el-icon style="margin-right: 5px;">
								<Share />
							</el-icon><span>Share</span>
						</p> -->
					</el-card>
					<div style="text-align: center;">
						<p v-html="result"></p>
					</div>
					<el-card shadow="hover"
						style="width: 1100px;height: 650px;background-color: white;margin: 30px auto;">
						<div>
							<p
								style="font-weight: bold;font-family: SourceHanSansSC-Bold;text-align: center;font-size: 20px;">
								Citing: Being/Predicting as Highly Cited Paper</p>
							<!-- <div v-if="isChart=='show'"> -->
							<div
								style="line-height: 4px;writing-mode: tb-rl;text-align: right;position: absolute;margin: 238px 0px 0px 95px;font-family: PingFang-SC-Bold;font-weight: bold;">
								<p>Monthly Status</p>
								<p>2022</p>
							</div>
							<div class="chart">
								<div id="chart" :style="{ width: '800px', height: '500px',margin:'auto'}"></div>
							</div>
							<p
								style="font-weight: bold;font-family: SourceHanSansSC-Bold;text-align: center;margin-top: -40px">
								Threshod: Top 1% Highly Cited</p>
							<div style="float: right;line-height: 4px;">
								<p style="color: #b40b18;font-size: 14px;margin-top: 15px;">* Bechmark Update
									on 2022-07.</p>
								<p style="color: #b40b18;font-size: 14px;margin-top: 15px;">* The data was
									analysized from google scholars.</p>
							</div>
							<!-- </div> -->


						</div>
					</el-card>
					<div style="width: 530px;margin: auto;">
						<el-row class="mb-4">
							<el-button type="primary" @click="leaveComments = true"
								style="background-color: #6b57a5;border-color:#6b57a5;width: 250px;">Test My Paper
							</el-button>
							<el-button @click="share = true" style="border-color:#fe7568;width: 250px;color: #fe7568;">
								Subscribe Reports
							</el-button>
						</el-row>
					</div>
				</div>
				<div style="margin-top: 210px;">
					<div style="position: absolute;color: white;line-height: 8px;">
						<p>
							<el-icon style="color: #fe7568;margin-right: 5px;font-size: 18px;">
								<WarningFilled />
							</el-icon>
							<span style="font-weight: bolder;font-size: 18px;">Statement</span>
						</p>
						<p>[1] Type title and publishing year,you will receive real response immediately.
						</p>
						<p>[2] Testing results is for your reference.
						</p>
						<p>[3] Highly Cited Paper are selected bimonthly.</p>
					</div>
					<div style="float: right;">
						<div style="float: left;color: white;line-height: 8px;margin-right: 5px;">
							<p><span style="margin-right: 5px;">Download</span>
								<el-icon style="font-size: 18px;color: #fe7568;">
									<Download />
								</el-icon>
							</p>
							<p>High cited APP</p>
						</div>
						<div style="float: right;">
							<el-image style="" :src="require('@/assets/images/HCP-Qu3_22.jpg')"></el-image>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog v-model="leaveComments" title="Leave Comments" width="666px" draggable :close-on-click-modal=false>
			<el-form :model="leaveCommentsForm" label-position='top' size="small" :rules="leaveCommentsFormRules"
				ref="leaveCommentsForm">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Organization" required prop="fieldA">
							<el-input v-model="leaveCommentsForm.fieldA" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Name" required prop="fieldB">
							<el-input v-model="leaveCommentsForm.fieldB" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Tel" required prop="fieldC">
							<el-input v-model.number="leaveCommentsForm.fieldC" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Email" required prop="fieldD">
							<el-input v-model="leaveCommentsForm.fieldD" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="Services of interest" required prop="fieldE">
					<el-select v-model="leaveCommentsForm.fieldE" placeholder="Please select a zone">
						<el-option label="Highly cited papers query" value="Highly cited papers query" />
						<el-option label="Highly cited papers query" value="Highly cited papers query" />
						<el-option label="Reference tracking report" value="Reference tracking report" />
						<el-option label="Papers gap query" value="Papers gap query" />
					</el-select>
				</el-form-item>
				<el-form-item label="Promotion name" prop="fieldF">
					<el-input v-model="leaveCommentsForm.fieldF" autocomplete="off" />
				</el-form-item>
				<el-form-item label="Activity form" prop="fieldG">
					<el-input v-model="leaveCommentsForm.fieldG" type="textarea" :rows="5" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="leaveCommentsFormSubmit('leaveCommentsForm')"
						style="background-color: #6b57a5;border-color:#6b57a5;width: 200px;">SUBMIT</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog v-model="share" title="Share" width="666px" :close-on-click-modal=false>
			<el-form :model="shareForm" label-position='top' size="small" :rules="shareFormRules" ref="shareForm">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Your Name" required prop="fieldA">
							<el-input v-model="shareForm.fieldA" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Your Tel" required prop="fieldB">
							<el-input v-model.number="shareForm.fieldB" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="Addressee Name" required prop="fieldC">
							<el-input v-model="shareForm.fieldC" autocomplete="off" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Addressee Email" required prop="fieldD">
							<el-input v-model="shareForm.fieldD" autocomplete="off" />
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="Mail Title" prop="fieldF">
					<el-input v-model="shareForm.fieldF" type="textarea" :rows="5" />
				</el-form-item>
				<el-form-item label="Mail Massage" prop="fieldG">
					<el-input v-model="shareForm.fieldG" type="textarea" :rows="5" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="shareFormSubmit('shareForm')"
						style="background-color: #6b57a5;border-color:#6b57a5;width: 200px;">SUBMIT</el-button>
				</span>
			</template>
		</el-dialog>



	</div>
</template>
<script>
	import $http from "@/request/http";
	import * as echarts from "echarts";
	export default {
		data() {
			const publishingYear = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('Publishing Year is required!'))
				} else {
					if (this.form.fieldB < 2012 || this.form.fieldB > 2022) {
						callback(new Error("Year range 2012-2022!"));
					}
					return callback();
				}
			}
			return {
				citationsNum: 0,
				isChart: 'hide',
				Xdata: [0, 0, 0, 0, 0, 0],
				Ydata: ['2023 July.', '2023 May.', '2023 Mar.', '2023 Jan.', 'Nov.', 'Sep.'],
				result: 'You are  <span style="font-size: 26px;color: red;">?</span> citations needed for Highly CitedPaper!',
				leaveComments: false,
				share: false,
				form: {
					fieldA: '',
					fieldB: '',
					fieldC: '',
					fieldD: '',
					fieldE: '',
					fieldF: '',
					classification: 'startCalculating'
				},
				rules: {
					fieldA: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "title is required!"
					}, ],
					fieldB: [{
						validator: publishingYear,
						trigger: ['blur', 'change'],
					}, ],
					fieldC: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Journal is required!"
					}, ],
					fieldD: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Google Scholar Citations is required!"
					}, ],
					fieldF: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "E-mail is required!"
						},
						{
							type: 'email',
							message: 'Please input correct email address',
							trigger: ['blur', 'change'],
						},
					],

				},
				leaveCommentsForm: {
					fieldA: '',
					fieldB: '',
					fieldC: '',
					fieldD: '',
					fieldE: '',
					fieldF: '',
					fieldG: '',
					classification: 'testMyPaper '

				},
				leaveCommentsFormRules: {
					fieldA: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Organization is required!"
					}, ],
					fieldB: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Name is required!"
					}, ],
					fieldC: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "Tel is required!"
						},
						{
							type: 'number',
							message: 'Tel must be a number'
						},
					],
					fieldD: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "E-mail is required!"
						},
						{
							type: 'email',
							message: 'Please input correct email address',
							trigger: ['blur', 'change'],
						},
					],
					fieldE: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Services of interest required!"
					}, ],
				},
				shareForm: {
					fieldA: '',
					fieldB: '',
					fieldC: '',
					fieldD: '',
					fieldE: '',
					fieldF: '',
					classification: 'subscribeReports '
				},
				shareFormRules: {
					fieldA: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Your Name is required!"
					}, ],
					fieldB: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "Your Tel is required!"
						},
						{
							type: 'number',
							message: 'Tel must be a number'
						},
					],
					fieldC: [{
						required: true,
						trigger: ['blur', 'change'],
						message: "Addressee Name is required!"
					}, ],
					fieldD: [{
							required: true,
							trigger: ['blur', 'change'],
							message: "E-mail is required!"
						},
						{
							type: 'email',
							message: 'Please input correct email address',
							trigger: ['blur', 'change'],
						},
					],
				}
			};
		},
		methods: {
			startCalculating(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.keleHightedSubject("keleHightedSubject", this.form, "subjectLoading")
							.then(function(res) {
								_this.form.subject = res.subject
								if (res.returnCode == 400) {
									_this.$notify.success({
										title: "error",
										message: "Your journal cannot be found in the list of journals included",
									});
									_this.result =
										'Your journal cannot be found in the list of journals included'
								} else if (res.returnCode == 200) {
									_this.$notify.success({
										title: "success",
										message: 'You are ' + res.result +
											'citations needed for Highly CitedPaper!',
									});
									_this.result = 'You are  <span style="color: #fe7568;font-size: 26px;"> ' +
										res.result +
										' </span>&nbsp; citations needed for Highly CitedPaper!'
									_this.citationsNum = _this.citationsNum - 0 + 1
								} else if (res.returnCode == 201) {
									_this.$notify.success({
										title: "success",
										message: 'you have exceeded the threshold of highly cited papers ' +
											res.result + ' citations'

									});
									_this.result =
										'you have exceeded the threshold of highly cited papers &nbsp;<span style="color: #fe7568;font-size: 26px;">' +
										res.result + '</span>&nbsp; citations'
									_this.citationsNum = _this.citationsNum - 0 + 1
								}
								$http.post("keleAddForm", _this.form)
									.then(function(res2) {
										_this.isChart = res2.data.isChart
										if (res2.data.isChart == 'show') {
											_this.Ydata = res2.data.histogramYData
											_this.Xdata = res2.data.histogramXData
										} else {
											_this.Xdata = [0, 0, 0, 0, 0, 0]
										}
										setTimeout(() => {
											_this.echartsInit()
										}, 1000);

									})
									.catch(function() {});
							})
							.catch(function() {});


					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			leaveCommentsFormSubmit(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("keleAddForm", this.leaveCommentsForm, "subjectLoading")
							.then(function(res) {
								_this.$notify.success({
									title: "success",
									message: res.msg,
								});
								_this.$refs[form].resetFields();
								_this.leaveComments = false
							})
							.catch(function() {});
					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			shareFormSubmit(form) {
				let _this = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("keleAddForm", this.shareForm, "subjectLoading")
							.then(function(res) {
								_this.$notify.success({
									title: "success",
									message: res.msg,
								});
								_this.$refs[form].resetFields();
								_this.share = false
							})
							.catch(function() {});
					} else {
						this.$notify.error({
							title: "Error",
							message: "Please check the required!",
						});
						return false;
					}
				});
			},
			getCitationsNum() {
				let _this = this
				$http.post("getCitationsNum")
					.then(function(res) {
						_this.citationsNum = res.data.citationsNum
					})
					.catch(function() {});
			},
			reset(form) {
				this.$refs[form].resetFields();
			},
			echartsInit() {
				let _this = this
				let column = echarts.init(document.getElementById("chart"));
				column.setOption({
					grid: {
						left: "15",
						containLabel: true,
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						},
						// formatter: function(params) {
						// 	let firstParams = params[0];
						// 	return firstParams.axisValueLabel + "<br>" + "value:" + firstParams.value
						// }

					},
					xAxis: [
						//x轴数据设置
						{
							type: "value",
							// name: "人数",
							min: 0,
							// max: 150,
							//interval: 30, //间隔数
							// splitNumber: 5,
							axisLabel: {
								formatter: "{value} ",
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: [
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
										"#7d7d7d",
									]
								},
							},
							axisLine: {
								//这是y轴文字颜色
								// lineStyle: {
								//     color: "#65C6E7",
								// },
							},
						},
					],
					yAxis: [{
						type: "category",
						offset: 0,
						axisLine: {
							//这是x轴文字颜色
							// lineStyle: {
							//     color: "#65C6E7",
							// },
						},
						data: _this.Ydata,
						axisPointer: {
							type: "shadow",
						},
						axisTick: {
							show: false,
						},
					}, ],
					series: [{
						data: _this.Xdata,
						type: "bar",
						barWidth: 35,
						label: {
							normal: {
								show: false,
								// position: "right",
							},
						},
						itemStyle: {
							//通常情况下：
							normal: {
								color: function(params) {
									var colorList = [
										"#100964",
										"#f9d950",
										"#47a178",
										"#fd5a3b",
										"#6a005f",
										"#b40b18",
									];
									return colorList[params.dataIndex];
								},
							},
						},
					}, ],
				});
			},
		},
		created() {
			this.getCitationsNum()
		},
		mounted() {
			this.echartsInit();

		},

	};
</script>

<style lang="scss" scoped>
	.bg1 {
		margin-bottom: 60px;
		height: 2000px;
		background-image: url("../assets/images/HCP-bg.png");
		border: 0 none;
		background-size: cover;
		background-repeat: no-repeat;
		// background-position: center;
	}

	.el-input__inner:focus {
		border-color: rgb(107 87 165);
	}

	.el-textarea__inner:focus {
		border-color: rgb(107 87 165);
	}
</style>
<style>
	.el-dialog__header {
		background: #f56c6c;
	}

	.el-dialog__headerbtn .el-dialog__close {
		color: black
	}
</style>
